@import url(https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Raleway+Dots|Roboto+Mono|Raleway|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway+Dots&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
@import url(https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap);
body, html {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-header {
    background-color:#090909;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
.App-main {
  padding-top: 30px;
    min-height: 95vh; 
  }

.App-footer {
    min-height: 5vh; 
  }  

.particle{
    position:fixed !important;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

.home-left {
	height: 100%;
}

.home-left-main {
	height: 100%;
	text-align: center;
}

.home-left-main-col {
	margin: auto;
	display: block;
}

.first-line {
	font-size: 4rem;
	font-family: "Sulphur Point", sans-serif;
}

.second_line {
	font-size: 2rem;
	font-family: "Sulphur Point", sans-serif;
}

mark.name-text {
	color: #e98900;
	background: none;
}

.typical {
	font-size: 1.6rem;
	font-family: "Sulphur Point", sans-serif;
}

.home-left-aboutme-main {
	position: relative;
	z-index: -1;
}

.home-left-aboutme {
	color: aliceblue;
}

.home-left-aboutme:hover {
	color: black;
}

.home-left-aboutmme-btn:hover {
	background: #666666;
}
.home_spacer {
    height: 10px;
}
.wave {
	-webkit-animation-name: wave-animation;
	        animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
	-webkit-animation-duration: 2.5s;
	        animation-duration: 2.5s; /* Change to speed up or slow down */
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite; /* Never stop waving :) */
	-webkit-transform-origin: 70% 70%;
	        transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
	display: inline-block;
}

@-webkit-keyframes wave-animation {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	10% {
		-webkit-transform: rotate(14deg);
		        transform: rotate(14deg);
	} /* The following five values can be played with to make the waving more or less extreme */
	20% {
		-webkit-transform: rotate(-8deg);
		        transform: rotate(-8deg);
	}
	30% {
		-webkit-transform: rotate(14deg);
		        transform: rotate(14deg);
	}
	40% {
		-webkit-transform: rotate(-4deg);
		        transform: rotate(-4deg);
	}
	50% {
		-webkit-transform: rotate(10deg);
		        transform: rotate(10deg);
	}
	60% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	} /* Reset for the last half to pause */
	100% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
}

@keyframes wave-animation {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	10% {
		-webkit-transform: rotate(14deg);
		        transform: rotate(14deg);
	} /* The following five values can be played with to make the waving more or less extreme */
	20% {
		-webkit-transform: rotate(-8deg);
		        transform: rotate(-8deg);
	}
	30% {
		-webkit-transform: rotate(14deg);
		        transform: rotate(14deg);
	}
	40% {
		-webkit-transform: rotate(-4deg);
		        transform: rotate(-4deg);
	}
	50% {
		-webkit-transform: rotate(10deg);
		        transform: rotate(10deg);
	}
	60% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	} /* Reset for the last half to pause */
	100% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
}



  .home-right{
    height: 100%;
  }

  .home-right-main{
    height: 100%;
  }

  .home-right-main-img{
      height: 65vh;
      margin: auto;    
      display: block;
  }

  .home-right-footer{
     width: 100%;
  }

.social-icons{
    text-align: center;
    font-size: 32px;

  }
 
  .fb{

    margin: 2%;
    color: white;
  }

  .fb:hover{
    font-size: 40px;

    color: #386ddd;
  }

  .git{
    margin: 2%;
    color: white;
  }

  .git:hover{
    font-size: 40px;

    color: #5C6BC0;
  }

  

  .insta{
    margin: 2%;
    color: white;
  }

  .insta:hover{
    font-size: 40px;

    color: #c1558b;
  }

  .linkedin{
    margin: 2%;
    color: white;
  }
  
  .linkedin:hover{
    font-size: 40px;

    color: #0e76a8;
  }
.footer{
    height: 100%;
    text-align: center;
    font-size: medium;
    color: rgb(202, 200, 200);
}


.About-header {
  background-color:#090909;

 }

.About-main {
  min-height: 95vh;
}

.d1{
  height: 100%;
  width: 100%;
 }

.d2{
  height: 100%;
  width: 100%;
 }

.d3{
  height: 100%;
  width: 100%;
 }

.About-footer {
  min-height: 5vh;
}


@media (max-width: 1200px) {
  .About-footer {
    margin-top: 250px;
  }
  
}

@media (max-width: 768px) {
  .About-footer {
    margin-top: 200px;
  }
  
}
@media (max-width: 600px) {
  .About-footer {
    margin-top: 250px;
  }
  
}

@media (max-width: 480px) {
  .About-footer {
    margin-top: 300px;
  }
  
}

@media (max-width: 440px) {
  .About-footer {
    margin-top: 350px;
  }
  
}
.education_header {

	font-size: 40px;

	/**text-align: center;**/
	color: white;

	margin-top: 100px;
	margin-left: 25px;
}


.education_card {
	border-radius: 25px;
	border: 3px solid white;
	padding: 20px;
	margin: 20px;

	width: auto;
	height: auto;
}


.education_image {
	width: 80%;
	height: auto;
}

.cat_circle {
	background-color: white;
	border-radius: 50%;
	width: 200px;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cat_circle:hover {
	color: rgb(253, 54, 54);
	box-shadow: 0 5px 15px;
}

.div_image {
	display: flex;
	justify-content: center;
	align-items: center;
}

.education_info {
	border-radius: 25px;
	background-color: aliceblue;
	padding: 20px;

	height: -webkit-max-content;

	height: max-content;
}





@media (max-width: 768px) {

	.education_header {

		margin-top: 200px;
		
	}


	.cat_circle {
		width: 175px;
		height: 175px;
	}
	.div_image {
		padding-bottom: 20px;
		
	}

	.education_info {
		height: -webkit-max-content;
		height: max-content;
	}

	.education_header {

		
		margin-top: 60px;
	}

	.button_div_education{
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
	}
	
}



:root {
  --overlay-color: rgba(31 , 31, 31 , 0.85);
  }

.menu-wrap {
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1;
   font-family: 'Poppins', sans-serif;
 }
 
 .menu-wrap .toggler {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 2;
   cursor: pointer;
   width: 50px;
   height: 50px;
   opacity: 0;
 }
 
 .menu-wrap .hamburger {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1;
   width: 80px;
   height: 80px;
   padding: 1rem;
   background: var(--primary-color);
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 /* Hamburger Line */
 .menu-wrap .hamburger > div {
   position: relative;
   flex: none;
   width: 50%;
   height: 2px;
   background: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: all 0.4s ease;

  
 }
 
 /* Hamburger Lines - Top & Bottom */
 .menu-wrap .hamburger > div::before,
 .menu-wrap .hamburger > div::after {
   content: '';
   position: absolute;
   z-index: 1;
   top: -10px;
   width: 100%;
   height: 2px;
   background: inherit;
 }
 
 /* Moves Line Down */
 .menu-wrap .hamburger > div::after {
   top: 10px;
 }
 
 /* Toggler Animation */
 .menu-wrap .toggler:checked + .hamburger > div {
   -webkit-transform: rotate(135deg);
           transform: rotate(135deg);
 }
 
 /* Turns Lines Into X */
 .menu-wrap .toggler:checked + .hamburger > div:before,
 .menu-wrap .toggler:checked + .hamburger > div:after {
   top: 0;
   -webkit-transform: rotate(90deg);
           transform: rotate(90deg);
 }
 
 /* Rotate On Hover When Checked */
 .menu-wrap .toggler:checked:hover + .hamburger > div {
   -webkit-transform: rotate(225deg);
           transform: rotate(225deg);
 }
 
 /* Show Menu */
 .menu-wrap .toggler:checked ~ .menu {
   visibility: visible;
 }
 
 .menu-wrap .toggler:checked ~ .menu > div {
   -webkit-transform: scale(1);
           transform: scale(1);
   transition-duration: var(--menu-speed);
 }
 
 .menu-wrap .toggler:checked ~ .menu > div > div {
   opacity: 1;
   transition:  opacity 0.01s ease 0.01s;
 }
 
 .menu-wrap .menu {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   visibility: hidden;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .menu-wrap .menu > div {
   background: rgba(31 , 31, 31 , 0.85);
   background: var(--overlay-color);
   width: 200vw;
   height: 200vw;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 
 .menu-wrap .menu > div > div {
   text-align: center;
   max-width: 100vw;
   max-height: 100vh;
   opacity: 0;
 }
 
 ul {
  position: relative;
}

ul li{
  list-style: none;
  text-align: center;
}

ul li a{
  color: #fff;
  text-decoration: none;
  font-size: 1.7em;
  padding: 5px 20px;
  display: inline-flex;
  font-weight: 700;
  transition: 0.5s;
}

ul:hover li a{
  color:  rgba(255,255,255,1);
}

ul li:hover a{
  font-size: 3em;
  color: #000;
  background: rgba(255,255,255,1);
}

ul li a:before{
  content: '';
  position: absolute;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 500px;
}

.experience_header {
	font-size: 40px;

	/**text-align: center;**/
	color: white;

	margin-top: 30px;
	margin-left: 25px;
}



.experience_card {
	border-radius: 25px;

	background-color: white;

	margin: 20px;

	width: auto;
	height: 300px;
}

.experience_image {
	width: 80%;
	height: auto;
}

.experience_image_small {
	width: 60%;
	height: auto;
}

.experience_circle {
	background-color: white;
	border-radius: 50%;

	color: rgb(68, 68, 68);
	box-shadow: 0 5px 20px;

	width: 200px;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;

	margin-top: 30px;
}

.div_image {
	display: flex;
	justify-content: center;
	align-items: center;
}

.experience_main {
	margin-top: 150px;
}

.experience_name {
	text-align: center;
}

.work_name {
	text-decoration: underline;
}
.experience_position {
	text-align: center;
}
.experience_year {
	text-align: center;
}
.experience_info {
	border-radius: 25px;
	background-color: aliceblue;
	padding: 20px;

	height: 300px;
}

.work-btn {
	margin-top: 20px;
}

@media (max-width: 1200px) {
	.spacer {
		height: 300px;
	}

	.spacer_small {
		height: 250px;
	}

	.spacer_small_second {
		height: 250px;
	}

	.spacer_footer{
		height: 360px;

	}

	.experience_circle {
		width: 175px;
		height: 175px;
	}
	.div_image {
		padding-bottom: 20px;
	}

	.experience_info {
		height: -webkit-max-content;
		height: max-content;
	}
}



@media (max-width: 768px) {
	.experience_circle {
		width: 175px;
		height: 175px;
	}
	.div_image {
		padding-bottom: 20px;
	}

	.experience_info {
		height: -webkit-max-content;
		height: max-content;
	}

	.experience_header {
		margin-top: 60px;
	}
	.spacer {
		height: 500px;
	}

	.spacer_small {
		height: 250px;
	}

	.spacer_small_second {
		height: 250px;
	}

	

	.button_div {
		display: flex;
		justify-content: center;
	}
}

@media (max-width: 600px) {
	.spacer_small {
		height: 20rem;
	}

	.spacer_small_second {
		height: 20rem;
	}
}
@media (max-width: 430px) {
	.spacer_small {
		height: 25rem;
	}

	.spacer_small_second {
		height: 25rem;
	}
}

@media (max-width: 350px) {
	.spacer_small {
		height: 450px;
	}

	.spacer_small_second {
		height: 450px;
	}
}
.Contact-header {
  background-color:#090909;
  color: white;
}

.Hamburger-menu{
  min-height: 7vh; 
}

.Contact-main {
  min-height: 88vh; 
 }

.Contact-left{
  margin: auto;    
  display: block;
}

.Contact-right{
  margin: auto;    
  display: block;
}

.Contact-footer {
  min-height: 5vh; 
}  


.Contact-text{
  text-align: center;
 }

.p-heading1{
  font-size: 4rem;
  font-family: 'Sulphur Point', sans-serif;
}

.p-heading2{
  font-size: 0.98rem;
  padding: 2.5%;
  font-family: 'Roboto Mono', monospace;
}

.contact-left-footer{
  font-size: 1.7rem;
}

.Contact-form{
    text-align: center;
    padding-bottom: 8%;
  }

.contact-form-header{
    font-size: 2.5rem;
    padding: 1%;
    font-family: 'Raleway', sans-serif;
   }

.contact_input{
   padding-left: 10%;
   padding-right: 10%;
  }

.contact_input_text{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.5rem;
    border-radius: 5%;
    text-align: center;
    background: transparent;
    color: white;
    min-height: 6.5vh;
  }
   
.contact_success_modal_body{
    text-align: center;
    background: #090909;
    color: aliceblue;
   }
   
.contact_success_modal_img{
     height: 15vh;
     padding-bottom: 3%;
   }
 
.contact-email-text-btn:hover{
      background: #666666;
      color: aliceblue;
  }
 
.Loading-header {
    background-color: #000000;
    background-image: url(/static/media/ap_logo.98f10c21.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

.main_row {
	font-family: "Sulphur Point", sans-serif;
	margin-top: 50px;
}
.card_col {
	padding-left: 2%;
	padding-right: 2%;

	padding-top: 2%;
}

.card_main {
	background: white;
	color: black;
	border-color: white;
	text-align: left;
}

.card_main:hover {
	transition: all 0.2s ease-out;
	top: -4px;
	box-shadow: 0px 4px 35px rgb(255, 255, 255);
}

.blockquote_desc{
	color: gray;

}

.blockquote-footer {
	color: black;
}

.icon {
	color: black;
	cursor: pointer;
}

.icon_images {
	visibility: collapse;
	color: black;
	cursor: pointer;
}

.icon_info {
	margin-left: 10px;
}


.icon:hover {
	color: rgb(255, 196, 102);
}


@media (max-width: 1200px) {
	.main_row {
		margin-top:70px;
	}

	.card_col {
		padding-right: 5%;
		padding-left: 5%;

		padding-bottom: 2%;
	}
}


@media (max-width: 768px) {
	.main_row {
		margin-top: 70px;
	}

	.card_col {
		padding-right: 10%;
		padding-left: 10%;

		padding-bottom: 5%;
	}
}

