@import url("https://fonts.googleapis.com/css?family=Abel|Raleway|Raleway+Dot|Asap|Barlow|Glegoo|Julius+Sans+One|Lato|Muli|Poiret+One|Sulphur+Point|Turret+Road&display=swap");

.main_row {
	font-family: "Sulphur Point", sans-serif;
	margin-top: 50px;
}
.card_col {
	padding-left: 2%;
	padding-right: 2%;

	padding-top: 2%;
}

.card_main {
	background: white;
	color: black;
	border-color: white;
	text-align: left;
}

.card_main:hover {
	transition: all 0.2s ease-out;
	top: -4px;
	box-shadow: 0px 4px 35px rgb(255, 255, 255);
}

.blockquote_desc{
	color: gray;

}

.blockquote-footer {
	color: black;
}

.icon {
	color: black;
	cursor: pointer;
}

.icon_images {
	visibility: collapse;
	color: black;
	cursor: pointer;
}

.icon_info {
	margin-left: 10px;
}


.icon:hover {
	color: rgb(255, 196, 102);
}


@media (max-width: 1200px) {
	.main_row {
		margin-top:70px;
	}

	.card_col {
		padding-right: 5%;
		padding-left: 5%;

		padding-bottom: 2%;
	}
}


@media (max-width: 768px) {
	.main_row {
		margin-top: 70px;
	}

	.card_col {
		padding-right: 10%;
		padding-left: 10%;

		padding-bottom: 5%;
	}
}
